html {
  scroll-behavior: smooth;
}

p,
li,
h1,
h2,
h3,
h4 {
  cursor: default;
}

.app-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 5rem;
}

.app-nav {
  position: static;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
  border-bottom: 1px solid #ababab;
}

.app-nav-sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  border-bottom: 1px solid #ababab;
  background-color: white;
}

.fill-top {
  margin-top: 120px;
}

@media only screen and (max-width: 768px) {
  .app-nav {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    border-bottom: 1px solid #ababab;
    background-color: white;
    padding: 0 1.6rem;
    box-sizing: border-box;
  }

  .app-container {
    padding: 0 1.6rem;
  }

  #root {
    padding-top: 0px;
  }
}

.payment-section {
  form {
    width: 30vw;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
}
