.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}

.list {
  display: flex;
}

.item {
  margin-right: 4rem;
  font-size: 1.4rem;
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 3px;
    background: white;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all 0.3s;
  }

  &:hover::after {
    width: 100%;
  }

  a {
    text-decoration: none;
    outline: none;
    color: white;

    &:hover,
    &:visited {
      color: white;
    }
  }
}

.footer {
  background: url(https://drawinganalysis.com/templates/defaultDesign/design/media/images/start-bg.jpg) 50% -50px no-repeat;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
}

.welcomeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}